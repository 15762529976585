import {Accordion, AccordionDetails, AccordionSummary, Button} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {TitleFormats} from "../../text/TitleFormats";
import {
  DataGrid, GridActionsCellItem, GridRowEditStopReasons, GridRowModes, GridToolbarContainer
} from "@mui/x-data-grid";
import {useState} from "react";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {randomId} from "@mui/x-data-grid-generator";
import AddIcon from "@mui/icons-material/Add";
import {deleteCompanyTitles, updateCompanyTitles} from "../../services/CompanyService";
import moment from "moment";

const CompanyTitleFormat = ({companyId, format, companyTitles, index}) => {

  const [rows, setRows] = useState(companyTitles);
  const [rowModesModel, setRowModesModel] = useState({});

  function EditToolbar(props) {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
      const id = randomId();
      setRows((oldRows) => [...oldRows, { id, title: '', status: 'NEW', isNew: true }]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
      }));
    };

    return (
        <GridToolbarContainer>
          <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
            Add record
          </Button>
        </GridToolbarContainer>
    );
  }

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    deleteCompanyTitles(id)
        .then(() => setRows(rows.filter((row) => row.id !== id)))
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    let updatedRow = {};

    if (newRow.isNew) {
      updatedRow = { ...newRow, isNew: false };
      updateCompanyTitles({title: updatedRow.title,
        status: updatedRow.status,
        companyId,
        titleFormat: format}).then(res => {
        updatedRow.id = res.data.id;
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)))
      })
    } else {
      updatedRow = {...newRow, isNew: false};
      updateCompanyTitles(updatedRow).then(r => setRows(rows.map((row) => (row.id
      === newRow.id ? updatedRow : row))));
    }
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    { field: 'title', flex: 2,
      minWidth: 150, headerName: 'Name',editable: true },
    { field: 'status', flex: 1, headerName: 'Status', editable: true, type: "singleSelect",  valueOptions: ["AVAILABLE", "PUBLISHED", "SCHEDULED"]},
    { field: 'postingDate',
      flex: 1, headerName: 'Posting Date', editable: false,
      valueFormatter: params => {
      if (params) {
        return moment.utc(params).format("DD/MM/YYYY hh:mm")
      } else {
        return "";
      }
      }},
    {
      flex: 1,
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: 'primary.main',
                }}
                onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
          />,
          <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
          />,
        ];
      },
    },
  ];

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  return <Accordion sx={{backgroundColor: "#F9FAFB", mt: "8px"}}>
    <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id={index}
    >
      {TitleFormats[format]}
    </AccordionSummary>
    <AccordionDetails>
      <DataGrid
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{
            toolbar: EditToolbar,
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel },
          }}
      />
    </AccordionDetails>
  </Accordion>
};

export default CompanyTitleFormat;
